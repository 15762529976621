@import '../../../../../../../assets/scss/colors';

.tab {
    cursor: pointer;
    padding: 8px 12px;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;
}

.tab.active {
    background-color: transparent;
    border-bottom: 2px solid $primary-color;
}