.copy-clipboard {
    &:hover {
        background-color: transparent;
        cursor: pointer;
    }

    svg {
        margin-left: 3px;
        font-size: 12px;
    }
}