@import "../../../../../assets/scss/globals";

nav {
  .dropdown-menu li {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
  }

  .dropdown-open {
    top: 50px;
  }

  @media only screen and (max-width: $smartphone-screen-width) {
    .dropdown-menu {
      position: absolute;
      top: 50px;
      left: 0;
      flex-direction: row;
    }
  }

  .menu-item {
    color: $white;
    display: flex;
    align-items: center;
    padding: 0.3rem 0.5rem;
    border-radius: 0.25rem;


    @media (min-width: $tablet-screen-width) {
      padding: 0;
    }

    &:hover {
      cursor: pointer;
      color: $grey;
    }
  }
}