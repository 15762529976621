@import "../../../../../assets/scss/globals";

.symbolLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  color: $black;
}

.symbolLink img {
  width: 30px;
  margin-right: 10px;
}