@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: 10 25 41;
    --color-secondary: 0 58 138;
    --color-indigo: 79 70 229;
  }
}
/* -------------------------------------------------------------------------- */
/*                                 Typography                                 */
/* -------------------------------------------------------------------------- */

h1,
h2 {
  display: block;
  margin-block: 0.67em 0.67em;
  margin-inline: 0 0;
  font-weight: 600;
}

h1 {
  font-size: 1.5em;
}

h2 {
  font-size: 1.2em;
}

p {
  margin: 0 0 0.35em;
}
/* -------------------------------------------------------------------------- */
/*                            Responsive Web Images                           */
/* -------------------------------------------------------------------------- */

img {
  max-width: 100%;
  height: auto;
}
/* -------------------------------------------------------------------------- */
/*                                    Input                                   */
/* -------------------------------------------------------------------------- */

input {
  padding: 0.375rem;
}