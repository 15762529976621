@import "../../../../../assets/scss/globals";

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.subTitleContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}


.companyAvatar {
  display: flex;
  margin-right: 15px;
  align-items: center;

  img {
    height: 50px;
    width: 50px;
    object-fit: contain;
  }
}

@media only screen and (max-width: $smartphone-screen-width) {
  .quoteTitle {
    font-size: 1rem;
  }
}