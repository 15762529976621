$primary-color: rgb(35 87 144);
$secondary-color: rgb(91 151 215);
$white: #fff;
$black: #000;
$cultured-grey: #f5f5f5;
$grey: rgb(107 114 128);
$philippine-green: rgb(34 197 94);
$chrimson-red: rgb(239 68 68);

.text-primary {
    color: $primary-color;
}

.text-secondary {
    color: $secondary-color;
}