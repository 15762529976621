@import '../../../../../assets/scss/globals';

footer {
    svg {
        fill: $cultured-grey !important;

        &:hover {
            fill: $white !important
        }
    }
}