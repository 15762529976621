@import "../../../../../assets/scss/globals";


.custom-btn {
    color: $white !important;
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem !important;
    justify-content: center;
    border-radius: 0.375rem;
    width: 100%;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.5rem;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
    background-color: $primary-color !important;


    &:hover {
        background-color: $secondary-color !important;
    }

    &:disabled {
        background-color: $grey !important;
    }

    .btn-icon {
        margin-right: .2rem;
    }

    @media only screen and (max-width: $smartphone-screen-width) {
        // Hide children node when icon displayed in smartphone view
        .hide-children {
            display: none;
        }
    }
}