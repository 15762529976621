.tab-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    align-self: flex-end;
}

.tab-container::-webkit-scrollbar {
    display: none;
}

.tabs {
    /* Display tabs in a row */
    display: inline-flex;

    button,
    button:hover {
        background-color: transparent;
    }
}