@import "../assets/scss/globals";
/* -------------------------------------------------------------------------- */
/*                                 App Styles                                 */
/* -------------------------------------------------------------------------- */

html {
  height: 100%;
}

body,
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: $cultured-grey;
}

main {
  flex: 1;
}

/** Fitting for sticky navigation */
.outlet-fitting {
  margin-top: 68px;
}

/* -------------------------------------------------------------------------- */
/*                                   Colors                                   */
/* -------------------------------------------------------------------------- */

.positiv {
  color: $philippine-green;
}

.negativ {
  color: $chrimson-red;
}

/* -------------------------------------------------------------------------- */
/*                                   Buttons                                  */
/* -------------------------------------------------------------------------- */

button,
button[type='submit'] {
  background-color: $primary-color;
}

button:hover {
  background-color: $secondary-color;
}

/* -------------------------------------------------------------------------- */
/*                                    Links                                   */
/* -------------------------------------------------------------------------- */

a {
  font-weight: 600;
  color: $primary-color
}

a:hover {
  color: $secondary-color;
}

/* -------------------------------------------------------------------------- */
/*                                     SVG                                    */
/* -------------------------------------------------------------------------- */

svg {
  margin-left: 2px;
  width: 16px;
  height: 16px;
}