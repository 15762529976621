@import "../../../../../assets/scss/globals";

.portfolioHeader {
  display: flex;
  justify-content: space-between !important;
  font-weight: 600;
}

.portfolioList {
  padding: 0 !important;
}

.portfolioList .MuiListItem-root {
  margin: 0;
  flex-shrink: 0;
  -ms-flex-negative: 0;
  border-width: 0;
  border-style: solid;
  border-color: rgb(0 0 0 / 12%);
  border-bottom-width: thin;
  background: $white;
}

.portfolioItem {
  justify-content: space-between !important;
}

.deleteIcon {
  cursor: pointer;
  color: $chrimson-red;
}
