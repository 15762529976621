@import "../../../../../assets/scss/globals";

.deepDiveContainer {
  padding: 1.25rem;
}


.deepDivListItem {
  display: flex;
  justify-content: space-between;

  img {
    width: 80px;
  }
}